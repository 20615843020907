import { Theme, useStyletron } from 'baseui'
import { Button as BaseUIButton } from 'baseui/button'
import { Colors } from 'baseui/themes'
import React from 'react'
import {
    BUTTON_KIND,
    BUTTON_KIND_CAPITALIZED,
    BUTTON_SHAPE,
    BUTTON_SIZE,
    BUTTON_TYPE,
    BUTTON_USE,
    BUTTON_USE_CAPITALIZED,
    IButton
} from './types'

export const DEFAULT_BUTTON_SHAPE = {
    [BUTTON_USE.default]: BUTTON_SHAPE.pill,
    [BUTTON_USE.icon]: BUTTON_SHAPE.circle,
    [BUTTON_USE.label]: BUTTON_SHAPE.pill,
    undefined: BUTTON_SHAPE.default
}

interface IExtendedColors extends Colors {
    buttonIconSecondaryText: string
}

interface IExtendedTheme extends Theme {
    colors: IExtendedColors
    controls: { buttons: Record<string, { hasDark: boolean }> }
}

const getButtonType = ($kind: BUTTON_KIND, $use: BUTTON_USE) => {
    const kind = BUTTON_KIND_CAPITALIZED[$kind as BUTTON_KIND]
    const use = BUTTON_USE_CAPITALIZED[$use]
    return BUTTON_TYPE[`${use}${kind}` as keyof typeof BUTTON_TYPE]
}

const getEnhancerColor = ($theme: IExtendedTheme, $use: BUTTON_USE, $kind: BUTTON_KIND, $dark: boolean) => {
    if ($use !== BUTTON_USE.default) return 'currentcolor'
    if ($kind === BUTTON_KIND.secondary) return 'currentcolor'
    const dark = $theme?.controls?.buttons[$kind]?.hasDark && $dark ? 'Dark' : ''
    return `button${getButtonType($kind, $use)}${dark}Text`
}

/*
 A button component that can be used to display a button with different styles and sizes, and usage.
 Please check theme file in .storybook/theme.ts and adapt your project theme for the correct behavior/look of the component.
*/

const Button: React.FC<IButton> = ({
    children,
    overrides,
    $size = BUTTON_SIZE.small,
    disabled = false,
    $kind = BUTTON_KIND.primary,
    $dark = false,
    $use = BUTTON_USE.default,
    $shape = DEFAULT_BUTTON_SHAPE[$use],
    ...baseProps
}) => {
    //Label and Icon primary will have different coloring
    const buttonType = React.useMemo(() => {
        const kind = BUTTON_KIND_CAPITALIZED[$kind as BUTTON_KIND]
        const use = BUTTON_USE_CAPITALIZED[$use]
        return BUTTON_TYPE[`${use}${kind}` as keyof typeof BUTTON_TYPE]
    }, [$kind, $shape])

    const [, theme] = useStyletron()

    const enhancerColor = React.useMemo(
        () => getEnhancerColor(theme as IExtendedTheme, $use, $kind, $dark),
        [$use, $kind, $dark, theme]
    )

    const styles = React.useMemo(
        () => ({
            ...overrides,
            BaseButton: {
                ...overrides?.BaseButton,
                style: ({ $theme }: { $theme: any }) => {
                    const dark = $theme?.controls?.buttons[$kind]?.hasDark && $dark ? 'Dark' : ''
                    return {
                        display: 'inline-flex',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                        paddingLeft:
                            $use === BUTTON_USE.icon
                                ? '0px'
                                : `calc(${$theme.sizing.scale400} + ${$theme.sizing.scale600})`,
                        paddingRight:
                            $use === BUTTON_USE.icon
                                ? '0px'
                                : `calc(${$theme.sizing.scale400} + ${$theme.sizing.scale600})`,
                        ...$theme.typography.font350,
                        ...($use === BUTTON_USE.icon
                            ? {
                                  height:
                                      $size === BUTTON_SIZE.small || $size === BUTTON_SIZE.compact
                                          ? '24px'
                                          : $size === BUTTON_SIZE.medium
                                          ? '32px'
                                          : '40px',
                                  width:
                                      $size === BUTTON_SIZE.small || $size === BUTTON_SIZE.compact
                                          ? '24px'
                                          : $size === BUTTON_SIZE.medium
                                          ? '32px'
                                          : '40px'
                              }
                            : {
                                  height:
                                      $size === BUTTON_SIZE.large
                                          ? '60px'
                                          : $size === BUTTON_SIZE.medium
                                          ? '48px'
                                          : $size === BUTTON_SIZE.small
                                          ? '40px'
                                          : '32px'
                              }),
                        transition: `color ${theme.animation.easeInOutCurve} .2s, background ${theme.animation.easeInOutCurve} .2s, border ${theme.animation.easeInOutCurve} .2s`,
                        color: $theme.colors[`button${buttonType}${dark}Text`],
                        backgroundColor: $theme.colors[`button${buttonType}${dark}Fill`],
                        ...($theme.borders[`button${buttonType}${dark}Border`]
                            ? {
                                  border: $theme.borders[`button${buttonType}${dark}Border`]
                              }
                            : {
                                  border: `2px solid ${
                                      $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Fill'}`]
                                  }`
                              }),
                        [':hover']: {
                            backgroundColor:
                                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}`],
                            color: $theme.colors[
                                `button${buttonType}${dark}${disabled ? 'DisabledText' : 'HoverText'}`
                            ],
                            border:
                                $theme.borders[
                                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}`
                                ] ||
                                `2px solid ${
                                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}`]
                                }`
                        },
                        [':active']: {
                            backgroundColor:
                                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Active'}`],
                            color: $theme.colors[
                                `button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}`
                            ],
                            border:
                                $theme.borders[
                                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}`
                                ] ||
                                `2px solid ${
                                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}`]
                                }`
                        },
                        [':focus']: {
                            backgroundColor:
                                $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Active'}`],
                            color: $theme.colors[
                                `button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}`
                            ],
                            border:
                                $theme.borders[
                                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}`
                                ] ||
                                `2px solid ${
                                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}`]
                                }`
                        },
                        [':selected']: {
                            backgroundColor:
                                $theme.colors[
                                    `button${buttonType}${dark}${disabled ? 'DisabledFill' : 'SelectedFill'}`
                                ],
                            color: $theme.colors[
                                `button${buttonType}${dark}${disabled ? 'DisabledText' : 'SelectedText'}`
                            ],
                            border:
                                $theme.borders[
                                    `button${buttonType}${dark}${disabled ? 'BorderDisabled' : 'HoverBorder'}`
                                ] ||
                                `2px solid ${
                                    $theme.colors[`button${buttonType}${dark}${disabled ? 'DisabledFill' : 'Hover'}`]
                                }`
                        },
                        [':disabled']: {
                            color: $theme.colors[`button${buttonType}${dark}DisabledText`],
                            backgroundColor: $theme.colors[`button${buttonType}${dark}DisabledFill`],
                            ...($theme.borders[`button${buttonType}${dark}BorderDisabled`]
                                ? {
                                      border: $theme.borders[`button${buttonType}${dark}BorderDisabled`]
                                  }
                                : { border: `2px solid ${$theme.colors[`button${buttonType}${dark}DisabledFill`]}` })
                        },
                        ...($shape === BUTTON_SHAPE.pill
                            ? { padding: $size !== BUTTON_SIZE.large ? '7px, 12px, 7px, 8px' : '9px, 20px, 9px, 20px' }
                            : {}),
                        ...overrides?.BaseButton?.style
                    }
                }
            },
            StartEnhancer: {
                style: ({ $theme }: { $theme: any }) => {
                    const dark = $theme.controls?.buttons[$kind].hasDark && $dark ? 'Dark' : ''
                    return {
                        justifyContent: 'end',
                        marginRight: '8px',
                        ...(disabled
                            ? {
                                  color: $theme.colors[`button${buttonType}${dark}DisabledText`],
                                  fill: $theme.colors[`button${buttonType}${dark}DisabledText`]
                              }
                            : {
                                  color: enhancerColor,
                                  fill: enhancerColor
                              }),
                        ...overrides?.StartEnhancer?.style
                    }
                }
            },
            EndEnhancer: {
                style: ({ $theme }: { $theme: any }) => {
                    const dark = $theme.controls?.buttons[$kind].hasDark && $dark ? 'Dark' : ''
                    return {
                        marginLeft: '8px',
                        ...(disabled
                            ? {
                                  color: $theme.colors[`button${buttonType}${dark}DisabledText`],
                                  fill: $theme.colors[`button${buttonType}${dark}DisabledText`]
                              }
                            : {
                                  color: enhancerColor,
                                  fill: enhancerColor
                              }),
                        ...overrides?.EndEnhancer?.style
                    }
                }
            }
        }),
        [$size, disabled, buttonType, $dark]
    )

    return (
        <BaseUIButton  {...baseProps} disabled={disabled} overrides={{ ...styles }} shape={$shape}>
            {children}
        </BaseUIButton>
    )
}

export default Button
